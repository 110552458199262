<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :z-index="999" :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Documentos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <BtnAcciones
                                :btnNombre="'Acciones'"
                                :btnColor="'color-58C5D8'"
                            >
                                <template v-slot:opciones>
                                    <div 
                                        @click="abrirModalReporte()" 
                                        class="btnAccionesItem py-2"
                                    >
                                        <span>Descargar reporte</span>
                                    </div>
                                </template>
                            </BtnAcciones>
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div>
                                        {{ item.cliente.nombre }}
                                    </div>
                                    <div class="tblSecundario">
                                        {{ item.empresa.nombre }}
                                    </div>
                                    <div v-if="item.cliente.nombre == null">
                                        General
                                    </div>
                                    <div class="tblSecundario" v-if="item.empresa.nombre == null">
                                        Incidencia General
                                    </div>
                                </td>
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto"> 
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre" 
                                                        class="pa-0 ma-0" 
                                                        v-model="documento.nombre" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <v-textarea
                                                    outlined
                                                    label="Descripción"
                                                    v-model="documento.descripcion"
                                                ></v-textarea>
                                            </v-col>
                                            <template v-if="rol == 'root'">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Cliente"
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors" 
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin'">
                                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            label="Empresa"
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            class="pa-0 ma-0"
                                                            required 
                                                            :error-messages="errors" 
                                                        >
                                                        </v-autocomplete>
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                       @click="cerrarModal()" 
                                       :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                        v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>

                                <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                    >
                                        El usuario no tiene permiso para editar el registro.
                                    </v-alert>
                                </div>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogReporte" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formReporte" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>

                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>

                                        <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                            <v-col cols="12" xs="12" :sm="rol == 'admin-empresa'?'12':'6'" :md="rol == 'admin-empresa'?'12':'6'" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Sucursal"
                                                    persistent-hint
                                                    v-model="sucursal_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>
                                        <template>
                                            <v-col cols="12" xs="12" :sm="rol == 'admin-empresa'?'12':'6'" :md="rol == 'admin-empresa'?'12':'6'" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="departamentos"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Departamento"
                                                    persistent-hint
                                                    v-model="departamento_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                item_text="nombre_completo"
                                                :items="itemsEmpleados"
                                                :valorDefecto="idsEmpleados"
                                                @dataMultiple="(data)=> idsEmpleados = data"
                                                label="Empleados"
                                                ref="multEmpleados"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="itemsTiposDocumentos"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Documentos"
                                                persistent-hint
                                                v-model="idDocumento"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cancelar</button>
                                <v-btn class="btnGuardar" @click="descargarReporte()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
//APIS
import documentoApi from "@/api/tipo_documentos";
import clienteAPi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import apiEmpleados from '@/api/empleados';

//COMPONENTS
import Datatable from '@/components/g-datatable/Datatable.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';
import BtnAcciones from '@/components/BtnAcciones.vue';

//PLUGINS
import queries from '@/queries/TipoDocumentos';
import Notify from '@/plugins/notify';
import gFunctions from '@/js/global_functions';

export default {
    components: {
        'data-table': Datatable,
        VuetifyMultipleAutocomplete,
        BtnAcciones
    },
    data() {
        return {
            columns         : [
                {
                    label: "Empresa",
                    name: "empresa",
                    filterable: false,
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            documento       : {
                id          : 0,
                nombre      : "",
                descripcion : "",
                empresa_id  : null,
                cliente_id  : null,
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            nombre          : null,
            isLoadingModal  : false,
            dialogReporte           : false,
            sucursales              : [],
            departamentos           : [],
            itemsEmpleados          : [],
            itemsTiposDocumentos    : [],
            sucursal_value          : null,
            departamento_value      : null,
            idsEmpleados            : [],
            idDocumento             : null,
            overlay                 : false,
            fraseOverlay            : '',
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
        }
    },
    watch: {
        cliente_value: function(val){
            this.cambiaCliente(val);
        },
        empresa_value: function(val){
            this.getSucursales()
            this.getTiposDocumentos()
        },
        sucursal_value: function(val){
            this.getDepartamentos()
        },
        departamento_value: function(){
            this.getEmpleados()
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal = true;
            
            if(accion == "add"){
                this.tituloModal = "Registrar tipo de documento.";
                this.imagen     = "/static/modal/tipoDocumentoCreate.svg";
                this.isLoadingModal = false;                
            } else {
                this.tituloModal = "Actualizar tipo de documento.";
                this.imagen     = "/static/modal/tipoDocumentoUpdate.svg";
                this.documento.id           = data.id;
                this.documento.nombre       = data.nombre;
                this.documento.descripcion  = data.descripcion;
                this.documento.cliente_id   = data.cliente_id;
                this.cliente_value          = data.cliente_id;
                this.documento.empresa_id   = data.empresa_id;
                this.empresa_value          = data.empresa_id;
                this.isLoadingModal         = false;         
            }
            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id, this.empresa_id);
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogReporte = false
            this.resetValues();
        },
        async resetValues() {
            this.documento  = {
                id          : 0,
                nombre      : "",
                descripcion : "",
                empresa_id  : null,
                cliente_id  : null,
            };
            this.cliente_value      = null;
            this.empresa_value      = null;
            this.sucursal_value     = null;
            this.departamentop_value= null;
            this.idsEmpleados       = []
            this.idDocumento        = null

            this.clientes           = [];
            this.empresas           = [];
            this.sucursales         = [];
            this.departamentos      = [];
            this.itemsEmpleados     = [];
            this.itemsTiposDocumentos = [];
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        eliminar(data){
            let texto = "";
            if(data.countEmpleados > 0){
                texto = "Este tipo de documento esta siendo usado por un empleado, ¿Aún así deseas eliminarlo?"
            }
            else{
                texto = "¿Estás seguro que deseas eliminar el tipo documento " + data.nombre + "?"
            }
            Notify.Alert(
                
                "ADVERTENCIA",
                texto,
                "Eliminar",
                () => {
                    this.isLoading = true;
                    documentoApi.deleteDocumento(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El documento ha sido eliminado satisfactoriamente.");
                        this.$apollo.queries.tipos_documento.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.documento.cliente_id = this.cliente_value;
                this.documento.empresa_id = this.empresa_value;

                this.isLoading = true;
                if(this.accion === "add") {
                    documentoApi.addDocumento(this.documento).then((response) => {
                        Notify.Success("Operación exitosa", "El tipo de documento se guardó satisfactoriamente.");
                        this.$apollo.queries.tipos_documento.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    documentoApi.updateDocumento(this.documento)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El tipo de documento se actualizó satisfactoriamente.");
                        this.$apollo.queries.tipos_documento.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        getClientes() {
            let parametros = { activo: true, paginate: false };
            clienteAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, empresa = null) {
            let parametros = { activo: true, paginate: false, cliente_id: id };
            if(id == null){
                return;
            }
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.accion != "add") {
                    this.documento.empresa_id = this.empresa_id;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        async getSucursales() {
            
            if(this.empresa_value == null){
                return;
            }

            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id : this.cliente_value,
                empresa_id : this.empresa_value
            };
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                this.getEmpleados();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },


        async getDepartamentos() {
            
            if(this.sucursal_value == null){
                return
            }
            
            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id : this.cliente_value,
                empresa_id : this.empresa_value,
                sucursal_id: this.sucursal_value
            };
            await departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
                this.getEmpleados();
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error);
            });
        },

        getEmpleados(){
            this.idsEmpleados = [];
            let parametros = { 
                paginate        : false,
                usuario_id      : this.datosLogin.id,
                cliente_id      : this.cliente_value, 
                rol_name        : this.datosLogin.rol.name,
                empresa_id      : this.empresa_value, 
                sucursal_id     : this.sucursal_value, 
                departamento_id : this.departamento_value, 
                activo          : true,
                tipo_empleado   : 'ambos'
            };

            apiEmpleados.getEmpleados(parametros).then((response)=>{
                this.itemsEmpleados = response.data;
                this.itemsEmpleados.map(emp => emp.nombre_completo);
            })
            .catch(error => {
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        async getTiposDocumentos() {
            
            if(this.empresa_value == null){
                return;
            }
            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value, 
                empresa_id : this.empresa_value
            };
            await documentoApi.getDocumentos(parametros).then(response => {
                this.itemsTiposDocumentos = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        loadModalData() {
            this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
        },
        setFilters() {
            this.filters = {AND:[]};
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let nombre  = this.nombre;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            }

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }
            
            this.paginationData.numberPage = 1;
            this.$apollo.queries.tipos_documento.refetch();

            this.nombre         = null;
            this.cliente_value  = null;
            this.empresa_value  = null;
            this.clientes       = [];
            this.empresas       = [];
        },
        async abrirModalReporte() {
            
            await this.resetValues();

            this.accion = 'REPORTE'
            
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            this.titulo_modal = "Descargar reporte"
            this.dialogReporte = true;
        },
        async descargarReporte() {
            this.overlay = true;
            this.fraseOverlay = "Descargando.";
            this.isSaving = true;
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "reportes/documentos-empleados";
            let parametros = {
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                tipo_documento_id: null,
            }

            if(this.idsEmpleados.length != 0){
                parametros.arrayEmpleados = this.idsEmpleados
            }

            if(this.rol == 'root'){
                parametros.cliente_id = this.cliente_value;
                parametros.empresa_id = this.empresa_value;
                parametros.sucursal_id = this.sucursal_value;
                parametros.departamento_id = this.departamento_value;
                parametros.tipo_documento_id = this.idDocumento;
            }
            else if( this.rol == 'admin'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.empresa_value;
                parametros.sucursal_id = this.sucursal_value;
                parametros.departamento_id = this.departamento_value;
                parametros.tipo_documento_id = this.idDocumento;
            }
            else if(this.rol == 'admin-empresa'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.sucursal_value;
                parametros.departamento_id = this.departamento_value;
                parametros.tipo_documento_id = this.idDocumento;
                parametros.arrayEmpleados = this.idsEmpleados;
            }
            else{
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
                parametros.departamento_id = this.departamento_value;
                parametros.tipo_documento_id = this.idDocumento;
            }

            let nombre_archivo = "Reporte_de_empleados_documentos.xlsx";

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                })
                .then((response) => {
                    self.overlay = false;
                    self.isSaving = false;
                    FileDownload(response.data, nombre_archivo);
                    self.cerrarModal();
                    
                })
                .catch(async error =>{
                    self.overlay = false;
                    self.isSaving = false;
                    self.cerrarModal();
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        let errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
                
            } catch (error) {
                console.log(error);
                self.overlay = false;
                self.isSaving = false;
                self.cerrarModal();
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("catalogo.tipos-documentos.store")
            this.permisos.update = permisosStorage.includes("catalogo.tipos-documentos.update")
            this.permisos.delete = permisosStorage.includes("catalogo.tipos-documentos.delete")
        },
        
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.permisosVista();
        this.filters = {AND:[]};
        if(this.rol == "root"){

        }
        else if (this.rol == "admin") {
            this.cliente_value = this.datosLogin.cliente_id;
            this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
        }
        else if (this.rol == "admin-empresa") {
            this.empresa_value =  this.datosLogin.empresa_id;
            this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
            this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
        }
        else {
            this.empresa_value =  this.datosLogin.empresa_id;
            this.sucursal_value = this.datosLogin.sucursal_id;
            this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
            this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
        }


    },
    mounted(){
        this.$apollo.queries.tipos_documento.skip = false;
    },
    apollo: {
        tipos_documento: {
            query       : queries.tipos_documentoTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.loading    = false;
                this.tableData  = this.tipos_documento;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .deshabilitado{
        background:#fcf6f6;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado:hover{
        background:#f2f2f2;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
</style>
